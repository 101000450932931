import { AutocompleteArrayInput, AutocompleteInput, BooleanInput, CheckboxGroupInput, Create, Edit, EditButton, FileField, FileInput, ImageField, ImageInput, NumberInput, SimpleForm, TextInput } from "react-admin"
import { LangInput } from "../../LangInput"
import { useEffect, useState } from "react";
import { RichTextInput } from "ra-input-rich-text";
import { CheckboxField } from "../../CheckboxField";

export const InertCreate = (props: any) => {
  const [types, setTypes] = useState<{ id: string; name: string }[]>([]);
  useEffect(() => {
    fetch("/api/inert/categories")
      .then((e) => e.json())
      .then((e) => {
        setTypes(e.map((f: any) => ({ id: f.id, name: f.id })))
      })
  }, [])

  return (
    <Create {...props}>
      <SimpleForm>
        <AutocompleteInput
          source="category"
          onCreate={(text) => {
            if (text) {
              setTypes([...types, { id: text, name: text }]);
              return { id: text, name: text };
            }
          }}
          choices={types}
          isRequired
        />

        <TextInput source="name" label="nome" />
        <TextInput source="name_in_table" label="Nome in tabella" />
        <NumberInput source="weight_mq" label="Ton a mq" />

        <LangInput source="description" label="Descrizione">
          <RichTextInput label="descrizione" source="description" />
        </LangInput>
        <NumberInput source="diametroPartenza" label="Diametro Partenza" />
        <NumberInput source="diametroArrivo" label="Diametro Arrivo" />

        <NumberInput source="minWeight" label="Peso minimo" />
        <NumberInput source="maxWeight" label="Peso massimo" />

        <NumberInput source="minimumQuantity" label="Peso minimo che può scegliere l'utente" />

        <TextInput source="type" label="Tipo"  />

        <TextInput source="article_name" label="Nome articolo gestionale" />
        <NumberInput source="ord_key" label="ordine" />

        <BooleanInput source="misto" label="Misto?" />
        <BooleanInput source="visibleInTable" label="visibile nella tabella?" />
        <BooleanInput source="visible" label="visibile nelle card?" />


        <BooleanInput source="enabled" />

        <ImageInput
          accept="image/*"
          maxSize={20 * 1024 * 1024}
          source="image"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <FileInput
          isRequired={false}
          accept="application/pdf"
          source="pdfs.pdf"
          label="Documento"
        >
          <FileField source="src" title="documento.pdf" />
        </FileInput>
        <FileInput accept={"video/mp4"} source="video" label="Video">
          <FileField source="src" title="video.mp4" />
        </FileInput>
      </SimpleForm>
    </Create>
  );
}