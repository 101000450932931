import { AutocompleteArrayInput, BooleanInput, Create, Edit, EditButton, NumberInput, SaveButton, SimpleForm, TextInput, Toolbar } from "react-admin"
import { LangInput } from "../../LangInput"
import { useEffect, useState } from "react"

export const RoleTypesEdit = (props: any) => {
    const [pages, setPages] = useState<{ id: string, name: string }[]>([])

    useEffect(() => {
        fetch("/api/role-types/pages").then(e => e.json()).then(e => {
            setPages(e.map((f: any) => ({ id: f.id, name: f.id })))
        })
    }, [])
    
    return (
        <Edit {...props} actions={false}>
            <SimpleForm toolbar={<CustomToolbar />}>
                <LangInput source="name" label="Nome">
                    <TextInput source="description" label="Descrizione" />
                </LangInput>

                <NumberInput source="order" label="Ordine" />
                <BooleanInput source="visible" label="Visibile" />
                <BooleanInput source="isPrivateType" label="Private?" defaultChecked={false} />
                <AutocompleteArrayInput
                    source="pages"
                    onCreate={(text) => {
                        if (text) {
                            setPages([...pages, { id: text, name: text }]);
                            return { id: text, name: text };
                        }
                    }}
                    choices={pages}
                    isRequired
                />
            </SimpleForm>
        </Edit>
    )
}
const CustomToolbar = (props: any) => <Toolbar {...props}><SaveButton /></Toolbar>
