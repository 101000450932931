import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, DeleteResult, HttpError, UpdateResult } from 'react-admin';
import { updateImages } from './_update_images';

const base_provider = jsonServerProvider('/api');

export const machineryProvider = {
  ...base_provider,
  create: async (resource: any, params: any): Promise<CreateResult<any>> => {
    const data = params.data;
    delete data.video;
    delete data.pdfs;

    const res = await fetch(`/api/machinery`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({...data, status: JSON.stringify(data.status)}),
    });

    if (res.status !== 200) {
      throw new Error(`http error: ${res.status}`)
    }

    const { id } = await res.json()

    if (params.data.main_image) {
      const body = new FormData()
      body.append(
        id +
        params.data.main_image.title.substring(
          params.data.main_image.title.lastIndexOf(".")
        ),
        params.data.main_image.rawFile
      )
      fetch(`/api/images/machinery/${id}/main`, { method: "PUT", body }).catch((e) => {
        throw e
      })
    }
    if (params.data.images) {
      await Promise.all(
        params.data.images.map((img: any) => {
          const body = new FormData()
          body.append(img.title, img.rawFile);
          return fetch(`/api/images/machinery/${id}`, {
            method: "PUT",
            body,
          }).catch((e) => {
            throw e;
          });
        })
      );
    }

    if (params.data.pdfs) {
      const body = new FormData();
      body.append("documento.pdf", params.data.pdfs.pdf.rawFile)
      fetch(`/api/documents/machinery/documents/${params.data.id}/`, { method: "PUT", body }).catch(e => { throw (e) })
      delete params.data.pdfs;
    }
    if (params.data.video) {
      const body = new FormData()
      body.append("video.mp4", params.data.video.rawFile);
      fetch(`/api/videos/machinery/video/${params.data.id}/`, { method: "PUT", body }).catch(e => { throw (e) })
    }


    return { data: { id: params.data.id } };
  },
  update: async (resource: any, params: any): Promise<UpdateResult<any>> => {

    updateImages(params.data.images, params.previousData.images, {
      main_image: { path: `/api/images/machinery/${params.data.id}/main`, name: params.data.id },
      aux: `/api/images/machinery/${params.data.id}/`
    })

    await new Promise((res) => setTimeout(res, 500));
    if (params.data.pdfs.pdf) {
      const body = new FormData()
      body.append("documento.pdf", params.data.pdfs.pdf.rawFile)
      fetch(`/api/documents/machinery/documents/${params.data.id}/`, { method: "PUT", body }).catch(e => { throw (e) })
    } else fetch(`/api/documents/machinery/documents/${params.data.id}/document.pdf`, { method: "DELETE" }).catch(e => { throw (e) })

    await new Promise((res) => setTimeout(res, 500));
    if(params.data.video){
      const body = new FormData();
      body.append("video.mp4", params.data.video.rawFile);
      fetch(`/api/videos/machinery/videos/${params.data.id}/`, {method: "PUT", body}).catch(e => {throw e})
    }
    await new Promise((res) => setTimeout(res, 500))
    delete params.data.images;
    delete params.data.video;
    delete params.data.pdfs;

    const data = params.data;
    const res = await fetch(`/api/machinery/${params.previousData.id}/`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({...data, status: JSON.stringify(data.status)}),
    });

    if (res.status !== 200) {
      let body = await res.json();
      throw new HttpError(body.message, res.status, "");
    }

    return { data: Object.assign(params.data, { id: params.previousData.id }) };
  },

  delete: async (resource: any, params: any): Promise<DeleteResult<any>> => {
    updateImages([], params.previousData.images, {
      image: { path: `/api/images/machinery/${params.id}/main`, name: params.id },
      aux: `/api/images/machinery/${params.id}/`
    })

    await new Promise((res) => setTimeout(res, 500));

    try{
      fetch(`/api/documents/machinery/documents/${params.id}/document.pdf`, {method: "DELETE"}).catch(e => {throw e})
    }catch(e) {}
    await new Promise((res) => setTimeout(res, 500))

    try{
      fetch(`/api/videos/machinery/videos/${params.id}/video.mp4`, {method: "DELETE"}).catch(e => {throw e})
    }catch(e) {}
    await new Promise((res) => setTimeout(res, 500))
    
    delete params.previousData.images
    delete params.previousData.pdfs;
    delete params.previousData.video;
    
    const res = await fetch(`/api/machinery/${params.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        id: params.id,
      })
    })

    if (res.status !== 200) {
      let body = await res.json();
      throw new HttpError(body.message, res.status, "");
    }
    return { data: Object.assign(params.data, { id: params.previousData.id }) }
  }
}