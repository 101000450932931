import { BooleanInput, Datagrid, DeleteButton, EditButton, List, NumberField, TextField, TextInput } from "react-admin";
import { LangListField } from "../../LangListField";
import { ImageSourceField } from "../../ImageSource";
import { CheckboxField } from "../../CheckboxField";

const filters = [
  <TextInput label="Search" source="q_Lname" alwaysOn />,
  <TextInput label="Search article name" source="q_Larticle_name" alwaysOn />,
  <BooleanInput label="enabled" source="q_enabled" />
]

export const InertList = (props: any) => {
  return (
    <List {...props} filters={filters}>
      <Datagrid rowClick="show">
        <ImageSourceField
          source="images.image"
          res="thumb"
          height="100px"
          label="Immagine"
        />
        <TextField source="name" label="Nome" />
        <LangListField source="description" label="Descrizione" />
        <NumberField source="diametroPartenza" label="da" />
        <NumberField source="diametroArrivo" label="a" />
        <TextField source="article_name" label="Nome articolo" />
        <CheckboxField source="enabled" />
        <EditButton />
      </Datagrid>
    </List>
  )
}