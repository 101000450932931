import { AutocompleteArrayInput, BooleanInput, Create, NumberInput, SimpleForm, TextInput } from "react-admin"
import { LangInput } from "../../LangInput"
import { useEffect, useState } from "react"

export const RoleTypesCreate = (props: any) => {
    const [pages, setPages] = useState<{ id: string, name: string }[]>([])

    useEffect(() => {
        fetch("/api/role-types/pages").then(e => e.json()).then(e => {
            setPages(e.map((f: any) => ({ id: f.id, name: f.id })))
        })
    }, [])

    return (
        <Create {...props}>
            <SimpleForm>
                <LangInput source="name" label="Nome">
                    <TextInput source="description" required />
                </LangInput>

                <NumberInput source="order" label="Ordine" />
                <BooleanInput source="visible" label="Visibile" defaultValue={true} />
                <BooleanInput source="isPrivateType" label="Private?" defaultChecked={false} />
                <AutocompleteArrayInput
                    source="pages"
                    onCreate={(text) => {
                        if (text) {
                            setPages([...pages, { id: text, name: text }]);
                            return { id: text, name: text };
                        }
                    }}
                    choices={pages}
                    isRequired
                />
            </SimpleForm>

        </Create>
    )

}