import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, DeleteResult, HttpError, UpdateResult } from 'react-admin';
import { updateImages } from './_update_images';

const base_provider = jsonServerProvider('/api');

export const homepageSwiperItemsProvider = {
    ...base_provider,
    create: async (resource: any, params: any): Promise<CreateResult<any>> => {
        const data = params.data


        const res = await fetch("/api/homepageswiperitems", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...data, description: JSON.stringify(data.description) })
        })

        if (res.status !== 200) {
            throw new Error(`http error: ${res.status}`)
        }

        const { id, } = await res.json()


        if (params.data.image) {
            const body = new FormData();
            body.append(id + params.data.image.title.substring(params.data.image.title.lastIndexOf(".")), params.data.image.rawFile)
            fetch(`/api/images/homepageswiperitems/images/${id}`, { method: "PUT", body }).catch(e => { throw e })
        }


        return { data: { id } }
    },
    update: async (resource: any, params: any): Promise<UpdateResult<any>> => {
        // console.log(params.data.image)
        console.log(params.previousData.id)
        if (params.data.images) {
            updateImages(params.data.images, params.previousData.images, {
                image: { path: `/api/images/homepageswiperitems/images/${params.previousData.id}/`, name: params.previousData.id },
            })
        }

        delete params.data.images

        const res = await fetch(`/api/homepageswiperitems/${params.previousData.id}/`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ...params.data,
                type: params.data.type.toString(),
                description: JSON.stringify(params.data.description),
            })
        })

        if (res.status !== 200) {
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return { data: Object.assign(params.data, { id: params.previousData.id }) }
    },
    delete: async (resource: any, params: any): Promise<DeleteResult<any>> => {
        updateImages([], params.previousData.image, {
            image: { path: `/api/images/homepageswiperitems/${params.id}`, name: params.id },
        })

        await new Promise((res) => setTimeout(res, 500))


        delete params.previousData.image
        const res = await fetch(`/api/homepageswiperitems/${params.id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: params.id,
            })
        })

        if (res.status !== 200) {
            let body = await res.json();
            throw new HttpError(body.message, res.status, "");
        }
        return { data: Object.assign(params.data, { id: params.previousData.id }) }
    }
}