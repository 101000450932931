import { BooleanInput, Datagrid, EditButton, ImageField, ImageInput, List, NumberField, TextField, TextInput } from "react-admin";
import { LangListField } from "../../LangListField";
import { ImageSourceField } from "../../ImageSource";
import { CheckboxField } from "../../CheckboxField";

const filters = [
    <TextInput label="search by name" source="q_Lname" alwaysOn />,
    <BooleanInput label="enabled" source="q_visible" alwaysOn />
]

export const HomepageswiperItemsList = (props: any) =>
    <List {...props} filters={filters}>
        <Datagrid>
            <ImageSourceField source="images.image" res="thumb" height="100px" label="Immagine" />
            <TextField source="name" label="Nome" />
            <LangListField source="description" />
            <TextField source="type" label="tipo" />
            <NumberField source="order" label="ordine" />
            <CheckboxField source="visible" />

          
            <EditButton />
        </Datagrid>
    </List>