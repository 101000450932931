import { AppBar, Button, useNotify } from "react-admin"
import { AppUserMenu } from "./UserMenu"
import { useLangContext } from "./LangContext"

import { Typography, Box } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export const AppAppBar = () => {
    const langContext = useLangContext()
    const notify = useNotify()
    const handleOnClick = () => {
        fetch("/api/slabgroup/update", { method: "PATCH" })
            .then(() => fetch("/api/slabtable/update", { method: "PATCH" })
                .then(() => fetch("/api/slabgallery/update", { method: "PATCH" })))
            .then(() => fetch("/api/product-slab-table/update", { method: "PATCH" }))

        fetch("/api/floortable/update", { method: "PATCH" })
        fetch("/api/blocktable/update", { method: "PATCH" })
        fetch("/api/product-floor-table/update", { method: "PATCH" })
        fetch("/api/product-block-table/update", { method: "PATCH" })

        notify("Viste aggiornate con successo", { type: "info" })
    }

    return <AppBar userMenu={<AppUserMenu />}>
        <Typography
            variant="h6"
            color="inherit"
            id="react-admin-title"
        >
            Ziche Backoffice -
        </Typography>
        <Box flex="1" />
        <Button label="Refresh View" sx={{ background: "white", height: "100%", color: "black", fontWeight: "bold", marginRight: '10px' }} onClick={handleOnClick} />
        <FormControl size="small" sx={{ m: 1, minWidth: 200, color: "white !important" }}>
            <Select
                value={langContext.lang}
                label="Lingua di visualizzazione"
                onChange={(e) => {
                    langContext.setLang(e.target.value)
                }}
            >
                <MenuItem value={"it"}>Italiano</MenuItem>
                <MenuItem value={"en"}>Inglese</MenuItem>
                <MenuItem value={"fr"}>Francese</MenuItem>
                <MenuItem value={"es"}>Spagnolo</MenuItem>
                <MenuItem value={"ru"}>Russo</MenuItem>
            </Select>
        </FormControl>
    </AppBar>
}