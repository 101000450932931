import { useEffect, useState } from "react"
import { AutocompleteInput, BooleanInput, Create, Edit, ImageField, ImageInput, NumberInput, SimpleForm, TextInput } from "react-admin"
import { LangInput } from "../../LangInput"
import { RichTextInput } from "ra-input-rich-text"

export const HomepageSwiperItemEdit = (props: any) => {

    const [types, setTypes] = useState<{ id: string, name: string }[]>([])

    useEffect(() => {
        fetch("/api/homepageswiperitems/types").then(e => e.json()).then(e => {
            setTypes(e.map((f: any) => ({ id: f.id, name: f.id })))

        })
    }, [])


    return <Edit {...props}>
        <SimpleForm>
            <AutocompleteInput source="type" onCreate={(text) => {
                if (text) {
                    setTypes([...types, { id: text, name: text }])
                    return { id: text, name: text }
                }
            }}
                choices={types}
                isRequired
            />

            <TextInput source="name" label="nome" />
            <LangInput source="description" label="descrizione">
                <RichTextInput label="descrizione" source="description" />
            </LangInput>

            <BooleanInput source="visible" label="visible" />
            <NumberInput source="order" label="order" />
            <TextInput source="link" label="link sito" />
            <ImageInput
                accept="image/*"
                maxSize={20 * 1024 * 1024}
                source="images.image"
                label="Immagine"
            >
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Edit>
}