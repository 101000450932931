import jsonServerProvider from 'ra-data-json-server';
import { CreateResult, DeleteResult, HttpError, UpdateResult } from 'react-admin';
import { updateImages } from './_update_images';

const base_provider = jsonServerProvider('/api');

export const inertProvider = {
    ...base_provider,
    create: async (resource: any, params: any): Promise<CreateResult<any>> => {
        const data = params.data
      

        const res = await fetch("/api/inert", {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ ...data, description: JSON.stringify(data.description) })
        })

        if (res.status !== 200) {
            throw new Error(`http error: ${res.status}`)
        }

        const { id, article_name } = await res.json()

        if (params.data.image) {
            const body = new FormData();
            body.append(id + params.data.image.title.substring(params.data.image.title.lastIndexOf(".")), params.data.image.rawFile)
            fetch(`/api/images/inerts/images/${article_name ?? id}`, { method: "PUT", body }).catch(e => { throw e })
        }

        if (params.data.pdfs) {
            const body = new FormData();
            body.append("documento.pdf", params.data.pdfs.pdf.rawFile);
            fetch(`/api/documents/inerts/documents/${article_name ?? id}/`, { method: "PUT", body }).catch(e => { throw e })
            delete params.data.pdfs;
        }

        if (params.data.video) {
            const body = new FormData();
            body.append("video.mp4", params.data.video.rawFile);
            fetch(`/api/videos/inerts/videos/${article_name ?? id}/`, { method: "PUT", body }).catch(e => { throw e })
        }

       
        return { data: { id } }
    },
    update: async (resource: any, params: any): Promise<UpdateResult<any>> => {
        if (params.data.images) {
            updateImages(params.data.images, params.previousData.images, {
                image: { path: `/api/images/inerts/images/${params.data.article_name ?? params.data.id}/`, name: params.previousData.article_name ?? params.previousData.id },
            })
        }

        if (params.data.pdfs.pdf) {
            const body = new FormData();
            body.append("documento.pdf", params.data.pdfs.pdf.rawFile);
            fetch(`/api/documents/inerts/documents/${params.data.article_name ?? params.data.id}/`, { method: "PUT", body }).catch(e => { throw e })
        }
        else {
            fetch(`/api/documents/inerts/documents/${params.data.article_name ?? params.data.id}/documento.pdf`, { method: "DELETE" }).catch(e => { throw e })
        }

        if (params.data.video) {
            const body = new FormData();
            body.append("video.mp4", params.data.video.rawFile);
            fetch(`/api/videos/inerts/videos/${params.data.article_name ?? params.data.id}/`, { method: "PUT", body }).catch(e => { throw e });
        } else {
            fetch(`/api/videos/inerts/videos/${params.data.article_name ?? params.data.id}/video.mp4`, { method: "DELETE" }).catch(e => { throw e });
        }
        await new Promise(res => setTimeout(res, 500));
        delete params.data.images
        delete params.data.pdfs;
        delete params.data.video;

        const res = await fetch(`/api/inert/${params.previousData.id}/`, {
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                ...params.data,
                type: params.data.type.toString(),
                description: JSON.stringify(params.data.description),
            })
        })

        if (res.status !== 200) {
            let body = await res.json()
            throw new HttpError(body.message, res.status, "")
        }

        return { data: Object.assign(params.data, { id: params.previousData.id }) }
    },
    delete: async (resource: any, params: any): Promise<DeleteResult<any>> => {
        updateImages([], params.previousData.images, {
            image: { path: `/api/images/inerts/${params.article_name ?? params.id}`, name: params.article_name ?? params.id },
        })

        await new Promise((res) => setTimeout(res, 500))
        try {
            fetch(`/api/documents/inerts/documents/${params.article_name ?? params.id}/documento.pdf`, { method: "DELETE" }).catch(e => { throw e })
        } catch (e) { }
        await new Promise((res) => setTimeout(res, 500))

        try {
            fetch(`/api/videos/inerts/videos/${params.article_name ?? params.id}/video.mp4`, { method: "DELETE" }).catch(e => { throw e })
        } catch (e) { }
        await new Promise((res) => setTimeout(res, 500))


        delete params.previousData.images
        delete params.previousData.pdfs
        delete params.previousData.video
        const res = await fetch(`/api/inert/${params.id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                id: params.id,
            })
        })

        if (res.status !== 200) {
            let body = await res.json();
            throw new HttpError(body.message, res.status, "");
        }
        return { data: Object.assign(params.data, { id: params.previousData.id }) }
    }
}